import React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import PageHeader from "../components/pageHeader"
import SEO from "../components/seo"

import ScrollAnimation from 'react-animate-on-scroll';

import ImageCoaching from '../components/images/imageCoaching.js'
import ImageDevelopement from '../components/images/imageDevelopement.js'
import ImageClimat from '../components/images/imageClimat.js'
import ImageChange from '../components/images/imageChange.js'
import ImageConflit from '../components/images/imageConflit.js'
import ImageLeft1 from '../components/images/imageLeft1.js'

const GestionChangement = () => (
  <Layout>
    <SEO title="Billets Mensuels | Gestion Mieux-Être" />
    <PageHeader
        header="Billets Mensuels"
        text="Abonnez-vous à nos billets mensuels pour rester connecté afin de recevoir nos conseils et de rester à l’affût de nos nouveautés."
        quote="En s’appuyant sur la mise en place de saines pratiques, nous pouvons contribuer activement à l’atteinte de vos succès.  <span>- Gestion mieux-être</span>"
        background="mieuxetre" />
    <div className="main-wrapper pad-page tcenter">
        <ScrollAnimation
            delay="0"
            animateIn="fadeIn">
            <h3>Nos Billets Mensuels</h3>
            <Link to="billets/demarquez-vous-avec-un-cv-qui-a-de-limpact">
                <p class="blog-link">Démarquez-vous avec un CV qui a de l’impact !</p>
            </Link>
            <Link to="billets/demarquez-vous-avec-un-cv-qui-a-de-limpact-2e-partie">
                <p class="blog-link">Démarquez-vous avec un CV qui a de l’impact ! (2e partie)</p>
            </Link>
        <Link className="button-link" to="/nous-joindre">Nous joindre</Link>
        </ScrollAnimation>
    </div>
  </Layout>
)

export default GestionChangement
